import React from 'react';

import './index.css';
import Logo from './logo.png';
import Nav from './nav';
import SearchBox from './search-box';

const Header = ({auth}) => {
  return (
    <header>
      <h1>
        <a href="/" title="Navigate home">
          <img src={Logo} alt="" />
        </a>
      </h1>
      <SearchBox />
      <Nav auth={auth} />
    </header>
  );
};

export default Header;
