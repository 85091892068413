import React from 'react';
import {Link} from 'react-router-dom';
import {formatPrice, formatProduct} from '../helper';

const SavedProducts = ({products, removeProduct}) => {
  // console.log('products:', products);
  return (
    <>
      <h3>Saved products</h3>
      <p>&nbsp;</p>
      <table width="100%" cellPadding="0" cellSpacing="0">
        <tbody>
          {products ? (
            Object.keys(products).length > 0 ? (
              Object.keys(products).map((productId) => {
                const product = formatProduct(products[productId]);
                return (
                  <tr key={productId}>
                    <td>
                      <Link to={`/product/${productId}`}>
                        <div>{product.product_name}</div>
                        {/* <div>{product.designer}</div> */}
                        <div>Original price: {formatPrice(product.price)}</div>
                        <div>
                          Discount price: {formatPrice(product.discount_price)}
                        </div>
                        <div>
                          Terms: {product.order.drop} / {product.order.term}
                        </div>
                      </Link>
                      <p>&nbsp;</p>
                    </td>
                    <td>
                      <button
                        type="button"
                        onClick={() => removeProduct(productId)}
                      >
                        remove
                      </button>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td>No saved products</td>
              </tr>
            )
          ) : (
            <tr>
              <td>Loading...</td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
};

export default SavedProducts;
