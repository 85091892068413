import React, {useState, useEffect} from 'react';
import {Link, Redirect, useParams} from 'react-router-dom';
import {
  formatPrice,
  formatProduct,
  fetchData,
  hasLocalData,
  setLocalData,
} from '../helper';
import './index.css';

const ProductPage = ({auth}) => {
  const {productId} = useParams();
  const [product, setProduct] = useState(null);
  const [lowestPrice, setLowestPrice] = useState(null);
  const [shouldRedirect, setShouldRedirect] = useState(false);

  useEffect(() => {
    fetchData(`search/?q=${productId}`).then((data) => {
      const product = formatProduct(data.rows[0]);
      setProduct(product);

      fetchData(`lowest-price/${productId}`).then((data) => {
        const row = data.rows[0];
        const lowestPrice = row && row.lowest_price;
        setLowestPrice(lowestPrice || product.lowest_price);
      });
    });
  }, [productId]);

  useEffect(() => {
    setShouldRedirect(product && auth.user && hasLocalData('product.url'));
  }, [auth.user, product]);

  const showDiscountPrice = true; // auth.user !== null;
  return shouldRedirect ? (
    <Redirect to="/profile" />
  ) : (
    <article className="ProductPage">
      {product ? (
        <div className="ProductPage-details">
          <div className="product-image">
            <img src={product.img_url} alt={product.product_name} />
          </div>
          <div>
            <h2>{product.product_name}</h2>
            <div className="designer">{product.designer}</div>
            <div className={`has-discount-${!!product.discount_price}`}>
              Original Price:{' '}
              <span className="price">{formatPrice(product.price)}</span>
              {showDiscountPrice ? (
                <span className="discount">
                  {' '}
                  {formatPrice(product.discount_price)}
                </span>
              ) : (
                <a
                  href="/"
                  className="discount"
                  onClick={(e) => {
                    e.preventDefault();
                    auth.signInWithGoogle();
                  }}
                >
                  Sign Up to See Member Price
                </a>
              )}
            </div>
            <p className="note">
              (The lowest price in the recent past is{' '}
              {showDiscountPrice && lowestPrice ? formatPrice(lowestPrice) : '...'})
            </p>
            <div className="buttons">
              {auth.user ? (
                <a
                  href={product.url}
                  rel="noopener noreferrer"
                  className="btn-secondary"
                  target="_blank"
                >
                  Buy It Now
                </a>
              ) : (
                <a
                  href="/"
                  className="btn-secondary"
                  onClick={(e) => {
                    e.preventDefault();
                    setLocalData('product.url', product.url);
                    auth.signInWithGoogle();
                  }}
                >
                  Buy It Now
                </a>
              )}

              <Link to={`/checkout/${productId}`} className="btn-primary">
                Buy It Later ⓘ
              </Link>
            </div>
            <p className="note">
              ⓘ Beauty Buy predicts that you'll get a better discount in the
              comming future. Use our deal tracker for free!
            </p>
          </div>
        </div>
      ) : (
        <div>Loading product</div>
      )}
    </article>
  );
};

export default ProductPage;
