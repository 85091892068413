import React, {useState, useEffect} from 'react';
import {utils} from 'glize';

const FilterBar = () => {
  const [brands, setBrands] = useState([]);

  useEffect(() => {
    const endpoint = 'brands-names';
    fetch(`/bff/api/v1/${endpoint}`)
      .then((response) => response.json())
      .then((data) => setBrands(data.rows))
      .catch((ex) => console.error('Could not load:', endpoint, ex));
  }, []);

  return (
    <form action="/search" className="filter-bar">
      <div>
        <label>
          SKIN CONCERN
          <select name="concern">
            <option value="">All skin concerns</option>
            <option value="acne_prone">Acne Prone</option>
            <option value="anti_ageing">Anti Ageing</option>
            <option value="dark_circles">Dark Circles</option>
            <option value="dry">Dry</option>
            <option value="normal_combination">Normal</option>
            <option value="oily">Oily</option>
            <option value="sensitive">Sensitive</option>
          </select>
        </label>
      </div>
      <div>
        <label>
          SAVINGS
          <select name="savings">
            <option value="">All savings</option>
            <option>Up to 25%</option>
            <option>25%-50%</option>
            <option>50%-75%</option>
            <option>More than 75%</option>
          </select>
        </label>
      </div>
      <div>
        <label>
          SKINCARE PRODUCTS
          <select name="skincare">
            <option value="">All skincare products</option>
            <option value="cleansers">Cleansers</option>
            <option value="exfoliators">Exfoliators</option>
            <option value="make_up_removers">Makeup Removers</option>
            <option value="toners">Toners</option>
            <option value="moisturisers">Moisturisers</option>
            <option value="lotions">Lotions</option>
            <option value="serums">Serums</option>
            <option value="oils">Oils</option>
            <option value="mists">Mists</option>
            <option value="balms">Balms</option>
            <option value="masks">Masks</option>
            <option value="peels">Peels</option>
            <option value="eye_care">Eye Care</option>
            <option value="lip_balm">Lip Balm</option>
            <option value="retinal_products">Retinal Products</option>
            <option value="supplements">Supplements</option>
            <option value="mineral">Mineral</option>
            <option value="spf30">SPF 30</option>
            <option value="spf50">SPF 50</option>
            <option value="tinted">Tinted</option>
            <option value="pharmacy_brands">Pharmacy Brands</option>
            <option value="acne_prone">Acne Prone</option>
            <option value="anti_ageing">Anti Ageing</option>
            <option value="dark_circles">Dark Circles</option>
            <option value="dry">Dry</option>
            <option value="normal_combination">Normal</option>
            <option value="oily">Oily</option>
            <option value="sensitive">Sensitive</option>
          </select>
        </label>
      </div>
      <div>
        <label>
          BRAND
          <select name="designers">
            <option value="">All brands</option>
            {brands.map((brand) => (
              <option key={utils.string.toKebabCase(brand.designer)}>
                {brand.designer}
              </option>
            ))}
          </select>
        </label>
      </div>
      <div>
        <label>
          PRICE RANGE
          <select name="price_range">
            <option value="">All price ranges</option>
            <option>&gt;$500</option>
            <option>Between $200 and $500</option>
            <option>Between $100 and $200</option>
            <option>Between $50 and $100</option>
            <option>Between $20 and $50</option>
            <option>&lt;$20</option>
          </select>
        </label>
      </div>
      <div>
        {' '}
        <button type="submit">Apply</button>
      </div>
    </form>
  );
};

export default FilterBar;
