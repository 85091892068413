import React, {useState, useEffect} from 'react';
import firebase from 'firebase/app';
import '@firebase/firestore';
import AccountDetails from './account';
import ProfileDetails from './profile';
import SavedProducts from './products';
import {getLocalData, removeLocalData} from '../helper';
import './index.css';

const ProfilePage = ({auth}) => {
  const [accountData, setAccountData] = useState(null);
  const [products, setProducts] = useState(null);

  useEffect(() => {
    if (auth.user) {
      const storage = firebase.firestore();

      storage
        .collection('users')
        .doc(auth.user.uid)
        .get()
        .then((snapshot) => {
          setAccountData(snapshot.exists ? snapshot.data() : null);
        });

      storage
        .collection('products')
        .doc(auth.user.uid)
        .get()
        .then((snapshot) => {
          setProducts(snapshot.exists ? snapshot.data() : null);
        });
    }
  }, [auth.user]);

  const saveAccountData = () => {
    firebase
      .firestore()
      .collection('users')
      .doc(auth.user.uid)
      .set(accountData, {merge: false})
      .then(() => {
        alert('Optional account details have been saved successfully.');
        continuePurchase();
      });
  };

  const continuePurchase = () => {
    const url = getLocalData('product.url');
    if (url) {
      removeLocalData('product.url');
      window.location.assign(url);
    }
  };

  const removeProduct = (productId) => {
    if (window.confirm('Are you sure you want to remove this product?')) {
      const data = {...products};
      delete data[productId];
      setProducts(data);
      firebase
        .firestore()
        .collection('products')
        .doc(auth.user.uid)
        .set(data, {merge: false})
        .then(() => alert('The product was removed'));
    }
  };

  return (
    <article className="ProfilePage">
      <ProfileDetails auth={auth} />
      <p>&nbsp;</p>
      <hr />
      <p>&nbsp;</p>
      <AccountDetails
        accountData={accountData}
        setAccountData={setAccountData}
        saveAccountData={saveAccountData}
        continuePurchase={continuePurchase}
      />
      <p>&nbsp;</p>
      <hr />
      <p>&nbsp;</p>
      <SavedProducts products={products} removeProduct={removeProduct} />
    </article>
  );
};

export default ProfilePage;
