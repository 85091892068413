import React from "react";
import "./index.css";

const AboutPage = () => {
  return (
    <article className="AboutPage">
      <h3>About BeautyBuy</h3>
      <p>
        From a girl to becoming a lady, the journey to beauty requires time,
        dedication, some precious trial-and-error learning processes both
        internally and externally. BeautyBuy v1 aims to become your external
        beauty assistant during every single step.
      </p>
      <p>
        We use machine learning to predict the most suitable skincare and
        cosmetics products and catch the best deals for you. With your
        permission, we track promotions on your behalf and automatically
        purchase your products at the price you desire within the time window
        you set.
      </p>
      <p>
        We believe that the marriage between machine learning and beauty will
        only flourish when the goal is to make an effective and smooth beauty
        journey for every single beauty buyer. Leave the burden of
        trial-and-error to our ‘machine,’ - use the least amount of time to find
        your most suitable beauty products within your budget.
      </p>
      <p>
        With love and respect, we only use your data to make you more beautiful.
      </p>
      <p>
        (We are a secret team of product and tech gurus in Silicon Valley with
        passion in the intersection of machine learning and beauty.)
      </p>
      <p>The BeautyBuy Team,<br/>December 2020</p>
    </article>
  );
};

export default AboutPage;
