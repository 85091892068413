import React, {useRef} from 'react';
import {Link} from 'react-router-dom';

const Nav = ({auth}) => {
  const checkbox = useRef();
  const closeMenu = () => (checkbox.current.checked = false);

  return (
    <nav id="nav">
      <input type="checkbox" aria-label="Toggle menu" ref={checkbox} />
      <div className="hamburger">
        <span></span>
      </div>
      <ul className="menu">
        <li className="mb">
          <Link to="/about" onClick={closeMenu}>
            About Us
           </Link>
        </li>
        <li>
          {auth.user ? (
            <Link to="/profile" onClick={closeMenu}>
              Profile
            </Link>
          ) : (
            <a
              href="/"
              onClick={(e) => {
                e.preventDefault();
                auth.signInWithGoogle();
                closeMenu();
              }}
            >
              Sign In
            </a>
          )}
        </li>
        <li>
          {auth.user ? (
            <a
              href="/"
              onClick={(e) => {
                e.preventDefault();
                auth.signOut();
                closeMenu();
              }}
            >
              Sign Out
            </a>
          ) : (
            <a
              href="/"
              className="btn-primary"
              onClick={(e) => {
                e.preventDefault();
                auth.signInWithGoogle();
                closeMenu();
              }}
            >
              Join Now
            </a>
          )}
        </li>
      </ul>
    </nav>
  );
};

export default Nav;
