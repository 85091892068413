import React, {useState, useEffect, useRef} from 'react';
import {useParams} from 'react-router-dom';
import firebase from 'firebase/app';
import '@firebase/firestore';
import {formatPrice, formatProduct, fetchData} from '../helper';
import './index.css';

const CheckoutPage = ({auth}) => {
  const {productId} = useParams();
  const [product, setProduct] = useState(null);
  const [orderData, setOrderData] = useState({term: '1 week', drop: '5off'});
  const saveButton = useRef();

  const handleChange = (event) => {
    const {name, value} = event.target;
    setOrderData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    fetchData(`search/?q=${productId}`).then((data) =>
      setProduct(formatProduct(data.rows[0]))
    );
  }, [productId]);

  const saveData = () => {
    if (auth.user) {
      saveButton.current.disabled = true;
      firebase
        .firestore()
        .collection('products')
        .doc(auth.user.uid)
        .get()
        .then((snapshot) => {
          const products = snapshot.exists ? snapshot.data() : {};
          products[productId] = {
            ...product,
            checkout_at: Date.now(),
            order: {...orderData, date: Date.now()},
          };

          firebase
            .firestore()
            .collection('products')
            .doc(auth.user.uid)
            .set(products, {merge: false})
            .then(() => window.location.assign('/profile'));
        });
    } else {
      auth.signInWithGoogle();
    }
  };

  const PriceDropOptions = ({product}) => {
    const drops = [5, 10, 20, 30, 50, 75];
    const price = product.price;
    return drops.map((percents) => {
      return (
        <option value={`${percents}off`} key={percents + price}>
          {formatPrice(price - price * (percents / 100))} ({percents}% off)
        </option>
      );
    });
  };

  return (
    <article className="ProductPage CheckoutPage">
      {product ? (
        <div className="ProductPage-details">
          <div className="product-image">
            <img src={product.img_url} alt={product.product_name} />
          </div>
          <div>
            <h2>{product.product_name}</h2>
            <div className="designer">{product.designer}</div>
            <div className={`has-discount-${!!product.discount_price}`}>
              <span className="price">{formatPrice(product.price)}</span>
              {auth.user ? (
                <span className="discount">
                  {' '}
                  {formatPrice(product.discount_price)}
                </span>
              ) : (
                <a
                  href="/"
                  className="discount"
                  onClick={(e) => {
                    e.preventDefault();
                    auth.signInWithGoogle();
                  }}
                >
                  Sign Up to See Member Price
                </a>
              )}
            </div>
            <p>&nbsp;</p>
            <h3>Notify me when deal becomes available:</h3>
            <table>
              <tbody>
                <tr>
                  <td colSpan="2">&nbsp;</td>
                </tr>
                <tr>
                  <td>Price drop to:</td>
                  <td>
                    <select name="drop" onChange={handleChange} value={orderData.drop}>
                      {product.discount_price && (
                        <PriceDropOptions product={product} />
                      )}
                    </select>
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">&nbsp;</td>
                </tr>
                <tr>
                  <td>Within the coming:</td>
                  <td>
                    <select name="term" onChange={handleChange}>
                      <option>1 week</option>
                      <option>2 weeks</option>
                      <option>30 days</option>
                      <option>60 days</option>
                      <option>180 days</option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">&nbsp;</td>
                </tr>
                <tr>
                  <td colSpan="2" align="right">
                    <button
                      className="btn-primary"
                      onClick={saveData}
                      ref={saveButton}
                    >
                      {auth.user ? 'Confirm' : 'Sign In and Continue'}
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div>Loading product</div>
      )}
    </article>
  );
};

export default CheckoutPage;
