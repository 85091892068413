import React from 'react';

const ProfileDetails = ({auth}) => {
  return auth.user ? (
    <div>
      <table>
        <tbody>
          <tr valign="top">
            <td width="120">
              {auth.user.photoURL ? (
                <img
                  height="100"
                  src={auth.user.photoURL}
                  alt={auth.user.displayName}
                />
              ) : (
                <svg
                  height="100"
                  viewBox="0 0 64 64"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M41.2452,33.0349a16,16,0,1,0-18.49,0A26.0412,26.0412,0,0,0,4,58a2,2,0,0,0,2,2H58a2,2,0,0,0,2-2A26.0412,26.0412,0,0,0,41.2452,33.0349ZM20,20A12,12,0,1,1,32,32,12.0137,12.0137,0,0,1,20,20ZM8.09,56A22.0293,22.0293,0,0,1,30,36h4A22.0293,22.0293,0,0,1,55.91,56Z"></path>
                </svg>
              )}
            </td>
            <td>
              <h3>{auth.user.displayName}</h3>
              <p>&nbsp;</p>
              <div>{auth.user.email}</div>
              <p>&nbsp;</p>
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  auth.signOut();
                }}
              >
                Sign out
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  ) : (
    <a
      href="/"
      onClick={(e) => {
        e.preventDefault();
        auth.signInWithGoogle();
      }}
    >
      Sign in
    </a>
  );
};

export default ProfileDetails;
