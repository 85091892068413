import {compressors, formatters} from 'glize';
import {useLocation} from 'react-router-dom';

export const hasLocalData = (key) => {
  const k = compressors.compress(btoa(key));
  return !!window.localStorage.getItem(k);
};

export const getLocalData = (key) => {
  const k = compressors.compress(btoa(key));
  const v = window.localStorage.getItem(k);
  return v && atob(compressors.decompress(v));
};

export const setLocalData = (key, value) => {
  const k = compressors.compress(btoa(key));
  const v = compressors.compress(btoa('' + value));
  window.localStorage.setItem(k, v);
};

export const removeLocalData = (key) => {
  const k = compressors.compress(btoa(key));
  return window.localStorage.removeItem(k);
};

export const formatProduct = (product) => {
  if (product) {
    product.product_id = product.product_id || product.prod_id;
    product.price = product.price || product.original_price || 0;
    product.discount_price =
      product.discount_price || product.current_discount_price || 0;
    product.url = formatURL(product.url);
  }

  return product;
};

export const formatPrice = (price) => {
  return formatters.formatNumber(price, {prefix: '$', fraction: 2});
};

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const fetchData = (endpoint) => {
  return fetch(`/bff/api/v1/${endpoint}`)
    .then((response) => response.json())
    .catch((ex) => console.error('Could not load:', endpoint, ex));
};

const formatURL = (/** @type {string} */ url) => {
  if (url.startsWith('https://www.dealmoon.com/exec/j?')) {
    try {
      let params = new URL(url).searchParams;
      url = params.get('url') || url;
      if (url.startsWith('https://kohls.sjv.io/')) {
        try {
          params = new URL(url).searchParams;
          url = params.get('u') || url;
        } catch (ex) {
          console.error('Could not parse URL:', url, ex);
        }
      }
    } catch (ex) {
      console.error('Could not parse URL:', url, ex);
    }
  }
  return url;
};
