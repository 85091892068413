import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {formatPrice, formatProduct, fetchData} from '../helper';

const Products = ({user, endpoint, size = 12}) => {
  const [products, setProducts] = useState(null);
  const [allProducts, setAllProducts] = useState(null);
  useEffect(() => {
    fetchData(endpoint)
      .then((data) =>  {
        setProducts(data.rows.slice(0, size))
        setAllProducts(data.rows.slice())
      }
    );
  }, [endpoint, size]);

  const renderProductList = (products) => {
    const showDiscountPrice = true; // user !== null;
    return products.map((product) => {
      product = formatProduct(product);
      return (
        <div key={`key-${product.product_id}`} className="product-card">
          <Link to={`/product/${product.product_id}`}>
            <div className="product-image">
              <img src={product.img_url} alt={product.product_name} />
            </div>
            <div className="product-info">
              <div className="designer">{product.designer}</div>
              <strong>{product.product_name}</strong>
              <div className={`has-discount-${!!product.discount_price}`}>
                <span className="price">{formatPrice(product.price)}</span>
                {showDiscountPrice && (
                  <span className="discount">
                    {formatPrice(product.discount_price)}
                  </span>
                )}
              </div>
            </div>
          </Link>
        </div>
      );
    })
  }

  const showMore = () => {
    const len = products.length;
    setProducts(allProducts.slice(0, len + 12))
  }

  const renderShowMore = () => {
    return (
       <button
        className="btn-secondary align-right"
        onClick={showMore}
      >
        Show more
      </button>
    )
  }

  return (
    <div className={`products ${endpoint}`}>
      {products ? (
        <>
        { products && renderProductList(products) }
        {renderShowMore()}
        </>
      )
       : (
        <div className="loading">Loading...</div>
      )}
    </div>
  );
};

export default Products;
