import React, {useEffect, useState} from 'react';
import {hasLocalData} from '../helper';

const AccountDetails = ({
  accountData,
  setAccountData,
  saveAccountData,
  continuePurchase,
}) => {
  const [hasPendingProduct, setHasPendingProduct] = useState(false);

  const handleChange = (event) => {
    const {name, value} = event.target;
    setAccountData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    setHasPendingProduct(hasLocalData('product.url'));
  }, []);

  return (
    <>
      <h3>Optional Account Details</h3>
      <table width="100%" cellPadding="0" cellSpacing="0">
        <tbody>
          <tr>
            <td colSpan="2">&nbsp;</td>
          </tr>
          <tr>
            <td align="right">Age group:</td>
            <td>
              <select
                name="age"
                onChange={handleChange}
                value={accountData?.age}
              >
                <option value="">Please select your age group</option>
                <option>Under 20</option>
                <option>20-25</option>
                <option>26-30</option>
                <option>31-35</option>
                <option>36-40</option>
                <option>41-45</option>
                <option>46-50</option>
                <option>Over 50</option>
              </select>
            </td>
          </tr>
          <tr>
            <td colSpan="2">&nbsp;</td>
          </tr>
          <tr>
            <td align="right">Beauty budget:</td>
            <td>
              <select
                name="budget"
                onChange={handleChange}
                value={accountData?.budget}
              >
                <option value="">
                  Please select your approximate annual budget
                </option>
                <option>Greater than 10k</option>
                <option>Between 5k and 10k</option>
                <option>Between 2k and 5k</option>
                <option>Between 1k and 2K</option>
                <option>Less than 1K</option>
              </select>
            </td>
          </tr>
          <tr>
            <td colSpan="2">&nbsp;</td>
          </tr>
          <tr>
            <td align="right">Skin type:</td>
            <td>
              <select
                name="skinType"
                onChange={handleChange}
                value={accountData?.skinType}
              >
                <option value="">Please select</option>
                <option>Normal</option>
                <option>Oily</option>
                <option>Dry</option>
                <option>Combination</option>
                <option>Sensitive</option>
                <option>Other</option>
              </select>
            </td>
          </tr>
          <tr>
            <td colSpan="2">&nbsp;</td>
          </tr>
          <tr>
            <td align="right">Skin concern:</td>
            <td>
              <select
                name="skinConcern"
                onChange={handleChange}
                value={accountData?.skinConcern}
              >
                <option value="">Please select</option>
                <option>Acne Prone</option>
                <option>Anti-Aging</option>
                <option>Blemish Prone</option>
                <option>Combination</option>
                <option>Dark Circle</option>
                <option>Dry</option>
                <option>Ingrown Hair</option>
                <option>Normal</option>
                <option>Oily</option>
                <option>Oily and Shinny Skin</option>
                <option>Pigmentation and Sun Damage</option>
                <option>Razor Run</option>
                <option>Redness and Rosacea</option>
                <option>Sensitive</option>
                <option>Sun Care</option>
                <option>Uneven Skin Tone</option>
              </select>
            </td>
          </tr>
          <tr>
            <td colSpan="2">&nbsp;</td>
          </tr>
          <tr>
            <td align="right">Ethnicity:</td>
            <td>
              <select
                name="ethnicity"
                onChange={handleChange}
                value={accountData?.ethnicity}
              >
                <option value="">Please select</option>
                <option>American Indian or Alaska Native</option>
                <option>Asian</option>
                <option>Black or African American</option>
                <option>Native Hawaiian or Other Pacific Islander</option>
                <option>White</option>
                <option>Hispanic or Spanish Origin</option>
                <option>Prefer not to say</option>
              </select>
            </td>
          </tr>
          <tr>
            <td colSpan="2">&nbsp;</td>
          </tr>
          <tr>
            <td align="right">Purchase frequency:</td>
            <td>
              <select
                name="purchaseFrequency"
                onChange={handleChange}
                value={accountData?.purchaseFrequency}
              >
                <option value="">
                  How often do you purchase skin care/cosmetics products
                </option>
                <option>Very infrequent</option>
                <option>Once every six month</option>
                <option>Once a quarter</option>
                <option>Once a month</option>
                <option>Very frequent</option>
              </select>
            </td>
          </tr>
          <tr>
            <td colSpan="2">&nbsp;</td>
          </tr>
          <tr>
            <td colSpan="2" align="right">
              {hasPendingProduct && (
                <>
                  <button
                    className="btn-secondary"
                    onClick={continuePurchase}
                    type="button"
                  >
                    Continue to Purchase
                  </button>
                  <span>&nbsp;</span>
                </>
              )}
              <button
                className="btn-primary"
                type="button"
                onClick={saveAccountData}
              >
                Save
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default AccountDetails;
