import React from 'react';
import {Link} from 'react-router-dom';
import {useQuery} from '../helper';
import './search-box.css';

const SearchBox = () => {
  const query = useQuery();

  return (
    <form action="/search">
      <input
        name="q"
        type="search"
        autoComplete="off"
        placeholder="Search for a brand or deals"
        aria-label="Search for a brand or deals"
        defaultValue={query.get('q') || ''}
      />
      <ul>
        <li>
          <Link to="/search/?skincare=all">Skincare</Link>
        </li>
        <li>
          <Link to="/search/?skincare=not">Makeup</Link>
        </li>
        <li>
          <Link to="/search/?designers=all">Brands</Link>
        </li>
        <li>
          <Link to="/search/?savings=all">Latest Deal</Link>
        </li>
        <li className="my-list">
          <Link to="/profile">My List</Link>
        </li>
        <li>
          <Link to="/about">About us</Link>
        </li>
      </ul>
    </form>
  );
};

export default SearchBox;
