import React from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';

import withFirebaseAuth from 'react-with-firebase-auth';
import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';
import firebaseConfig from './firebaseConfig';

import Header from './components/header';
import AboutPage from './components/about';
import Search from './components/search';
import ProductPage from './components/product';
import ProfilePage from './components/profile';
import CheckoutPage from './components/checkout';

import './styles/app.css';
import './styles/flex.css';
import './styles/theme.css';

const firebaseApp = firebase.initializeApp(firebaseConfig);
const firebaseAppAuth = firebaseApp.auth();
const providers = {
  googleProvider: new firebase.auth.GoogleAuthProvider(),
};
firebase.analytics();

const App = ({user, signOut, signInWithGoogle}) => {
  return (
    <Router>
      <Header
        auth={{
          user,
          signOut,
          signInWithGoogle,
        }}
      />
      <div id="main">
        <Switch>
          <Route path="/about">
            <AboutPage />
          </Route>
          <Route path="/search">
            <Search user={user} signInWithGoogle={signInWithGoogle} />
          </Route>
          <Route path="/product/:productId">
            <ProductPage
              auth={{
                user,
                signOut,
                signInWithGoogle,
              }}
            />
          </Route>
          <Route path="/checkout/:productId">
            <CheckoutPage
              auth={{
                user,
                signOut,
                signInWithGoogle,
              }}
            />
          </Route>
          <Route path="/profile">
            <ProfilePage
              auth={{
                user,
                signOut,
                signInWithGoogle,
              }}
            />
          </Route>
          <Route path="/">
            <Search user={user} signInWithGoogle={signInWithGoogle} />
          </Route>
        </Switch>
      </div>
      <footer>
        © {new Date().getFullYear()} BeautyBuy™. All Rights Reserved.
      </footer>
    </Router>
  );
};

export default withFirebaseAuth({
  providers,
  firebaseAppAuth,
})(App);
