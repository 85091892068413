import React from 'react';
import {useQuery} from '../helper';
import Products from './products';
import FilterBar from './filter'
import './index.css';

const Search = ({user, signInWithGoogle}) => {
  const query = useQuery();

  return (
    <div className="search">
      <article className="landing">
        <h2>Revolutionize the way you shop for Beauty.</h2>
        <p>Sign up for personalized product saving recommendations.</p>
        {!user && (
          <a
            className="btn-primary"
            href="/"
            onClick={(e) => {
              e.preventDefault();
              signInWithGoogle();
            }}
          >
            Sign Up
          </a>
        )}
      </article>
      <div className="search-result">
        <FilterBar />
        {query.toString().length ? (
          <Products user={user} endpoint={`search?${query.toString()}`} />
        ) : (
          <>
            <Products user={user} endpoint="recommended-products" />
            <h3>Best Deals</h3>
            <Products user={user} endpoint="best-deals" />
          </>
        )}
        <h3>Top Brands</h3>
        <Products user={user} endpoint="top-brands" />
      </div>
    </div>
  );
};

export default Search;
