const FIREBASE_API_KEY = "AIzaSyC9Di5QtTAQN3Fl2t61GSHIRszWuee3Lss";
const FIREBASE_PROJECT_ID = "beautybuy-4dcd8";
const FIREBASE_APP_ID = "1:484593557241:web:38e921d40286ad714d4c28";
const FIREBASE_MESSAGING_SENDER_ID = "484593557241";

const config = {
  apiKey: FIREBASE_API_KEY,
  authDomain: FIREBASE_PROJECT_ID + ".firebaseapp.com",
  databaseURL: "https://" + FIREBASE_PROJECT_ID + ".firebaseio.com",
  projectId: FIREBASE_PROJECT_ID,
  storageBucket: FIREBASE_PROJECT_ID + ".appspot.com",
  messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
  appId: FIREBASE_APP_ID,
  measurementId: "G-JVWF9L3DWQ",
};

export default config;
